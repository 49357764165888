import React from "react";
import Layout from './app/Layout'


class App extends React.Component {
  render() {
    return (
      <Layout />
    );
  }
}

export default App
